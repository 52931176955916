import {
	Home,
	Logout,
	PersonOutline,
	Schedule,
	Settings,
	Upload
} from '@mui/icons-material';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../ApplicationRoutes';
import { useAuth } from '../Contexts/AuthContext';

export const SideBar = () => {
	const navigate = useNavigate();
	const { username, email, logout } = useAuth();

	return (
		<Stack
			direction="column"
			alignItems="center"
			justifyContent="space-between"
			paddingY="20px"
			paddingX="20px"
			overflow="auto"
		>
			<Stack
				direction="column"
				width="100%"
				alignItems="left"
				spacing="15px"
			>
				<Typography
					fontSize="23px"
					fontWeight="bold"
				>
					ZoneVid
				</Typography>
				<Stack
					alignItems="center"
					direction="row"
					spacing="5px"
					onClick={() => {
						navigate(
							AppRoutes.Dashboard
						);
					}}
					style={{ cursor: 'pointer' }}
				>
					<Home />
					<Typography>
						Dashboard
					</Typography>
				</Stack>
				{/* <Stack
					alignItems="center"
					direction="row"
					spacing="5px"
					onClick={() => {
						navigate(
							AppRoutes.Gallery
						);
					}}
					style={{ cursor: 'pointer' }}
				>
					<PhotoAlbumOutlined />
					<Typography>
						Gallery
					</Typography>
				</Stack> */}
				<Stack
					alignItems="center"
					direction="row"
					spacing="5px"
					onClick={() => {
						navigate(
							AppRoutes.Schedule
						);
					}}
					style={{ cursor: 'pointer' }}
				>
					<Schedule />
					<Typography>
						Schedule
					</Typography>
				</Stack>
				<Stack
					alignItems="center"
					direction="row"
					spacing="5px"
					onClick={() => {
						navigate(
							AppRoutes.Upload
						);
					}}
					style={{ cursor: 'pointer' }}
				>
					<Upload />
					<Typography>
						Upload
					</Typography>
				</Stack>
				{/* <Stack
          alignItems="center"
          direction="row"
          spacing="5px"
          onClick={() => {
            navigate(AppRoutes.Analysitcs);
          }}
          style={{ cursor: 'pointer' }}
        >
          <Analytics />
          <Typography>Analytics</Typography>
        </Stack> */}
			</Stack>
			<Stack
				direction="column"
				width="100%"
				alignItems="left"
				spacing="15px"
			>
				{/* <Stack
          alignItems="center"
          direction="row"
          spacing="5px"
          onClick={() => {
            navigate(AppRoutes.Support);
          }}
          style={{ cursor: 'pointer' }}
        >
          <Support />
          <Typography>Support</Typography>
        </Stack> */}
				<Stack
					alignItems="center"
					direction="row"
					spacing="5px"
					onClick={() => {
						navigate(
							AppRoutes.Settings
						);
					}}
					style={{ cursor: 'pointer' }}
				>
					<Settings />
					<Typography>
						Settings
					</Typography>
				</Stack>
				<Divider
					sx={{
						borderBottomWidth:
							'2px',
					}}
					orientation="horizontal"
					flexItem
				/>
				<Stack
					alignItems="center"
					direction="row"
					spacing="5px"
				>
					<PersonOutline />
					<Stack direction="column">
						<Typography>
							{username}
						</Typography>
						<Typography fontSize="10px">
							{email}
						</Typography>
					</Stack>
					<IconButton
						onClick={logout}
						children={
							<Logout />
						}
					/>
				</Stack>
			</Stack>
		</Stack>
	);
};
