import { useEffect } from "react";
import type { TiktokAccounts } from "./SettingsPage";
import TiktokAccountItem from "./TiktokAccountItem";

interface Props {
	tiktokAccounts: TiktokAccounts[] | null;
}

export default function TikTokAccountList({ tiktokAccounts }: Props) {
	if (!tiktokAccounts) {
		return <></>;
	}

	return (
		<div className="flex flex-col gap-2">
			{tiktokAccounts.map((tiktokAccount) => (
				<TiktokAccountItem
					key={tiktokAccount.id}
					tiktokAccount={tiktokAccount}
				/>
			))}
		</div>
	);
}
