import {
	Stack,
	Divider,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	IconButton,
	Button,
} from "@mui/material";
// import { AccountCircle, Delete } from "@mui/icons-material";
import { useAuth } from "../../Contexts/AuthContext";
import { useEffect, useState } from "react";
import apiClient from "../../ApiClient";
import axios from "axios";
import TikTokAccountList from "./TiktokAccountList";

export interface TiktokAccounts {
	id: string;
	username: string;
	code: string;
	access_token: string;
	refresh_token: string;
	refresh_expires: string;
	privacy_level: "PRIVATE";
	user: number;
}

const mockTikTokAccounts: TiktokAccounts[] = [
	{
		id: "1",
		username: "hbakhsh",
		code: "123",
		access_token: "123",
		refresh_token: "123",
		refresh_expires: "1m",
		privacy_level: "PRIVATE",
		user: 123,
	},
	{
		id: "2",
		username: "redditwarrior200",
		code: "123",
		access_token: "123",
		refresh_token: "123",
		refresh_expires: "1m",
		privacy_level: "PRIVATE",
		user: 123,
	},
];

export const SettingsPage = () => {
	const { username, email } = useAuth();
	const [tiktokAccounts, setTiktokAccounts] = useState<TiktokAccounts[] | null>(
		null,
	);

	// ! Enable this data fetching once the endpoint has data to serve -> currently it's overriding the tiktokAccounts state
	useEffect(() => {
		const fetchAccounts = async (): Promise<void> => {
			await apiClient
				.get("core/tiktokaccounts/")
				.then((res) => {
					const tiktokAccounts = res.data;
					setTiktokAccounts(tiktokAccounts);
				})
				.catch((err) => {
					if (axios.isAxiosError(err)) {
						const errorMessage =
							err.response?.data?.message ||
							"An error occurred while fetching schedules.";
						// setError(errorMessage);
					}
				});
		};
		fetchAccounts();
	}, []);

	const handleAddTiktokAccount = async () => {
		await apiClient
			.get("/auth/tiktok/oauth/")
			.then((res) => res.status)
			.then((data) => {
				console.log(data);
				// Set window location -> redirect user to TikTok OAuth page
				// window.location.href =
			});
	};

	return (
		<Stack
			direction="column"
			flex={1}
			divider={<Divider orientation="horizontal" flexItem />}
		>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				paddingX="20px"
				paddingY="20px"
			>
				<Typography fontSize="25px" fontWeight="bold">
					Settings
				</Typography>
			</Stack>
			<Stack
				direction="column"
				alignItems="start"
				spacing="20px"
				paddingX="30px"
				paddingY="30px"
			>
				<Typography fontSize="20px" fontWeight="bold">
					Profile
				</Typography>
				<Stack
					direction="column"
					alignItems="start"
					justifyContent="center"
					spacing="5px"
					paddingLeft="20px"
				>
					<Stack direction="row" width="100%" spacing={2} alignItems="center">
						<Typography fontSize="15px" fontWeight="bold">
							User Name
						</Typography>
						<Typography color="grey">{username}</Typography>
					</Stack>
					<Stack direction="row" width="100%" spacing={2} alignItems="center">
						<Typography fontSize="15px" fontWeight="bold">
							Email
						</Typography>
						<Typography color="grey">{email}</Typography>
					</Stack>
				</Stack>
			</Stack>
			<Stack
				direction="column"
				alignItems="start"
				justifyContent="start"
				spacing="20px"
				paddingX="30px"
				paddingY="30px"
			>
				<Stack
					direction="row"
					width="100%"
					justifyContent="space-between"
					alignItems="center"
				>
					<div className="flex flex-col gap-2">
						<Typography fontSize="20px" fontWeight="bold">
							Linked Tiktok Accounts
						</Typography>
						<TikTokAccountList tiktokAccounts={tiktokAccounts} />
					</div>
					<Button
						variant="contained"
						style={{
							textTransform: "none",
						}}
						onClick={handleAddTiktokAccount}
					>
						Add Account
					</Button>
				</Stack>
				{/* PREVIOUS MATERIAL UI IMPLEMENTATION
				<TableContainer>
					<Table
						style={{
							border: "1px solid rgb(0,0,0,0.1)",
						}}
					>
						<TableBody>
							{accountNames.map((a, i) => (
								<TableRow>
									<TableCell align="center">
										<Stack direction="row" spacing={1}>
											<AccountCircle />
											<Typography>{a}</Typography>
										</Stack>
									</TableCell>
									<TableCell align="left">
										<Typography>{accountEmails[i]}</Typography>
									</TableCell>
									<TableCell align="right">
										<IconButton children={<Delete />} />
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer> */}
			</Stack>
			<Stack
				direction="column"
				alignItems="start"
				justifyContent="start"
				spacing="10px"
				paddingX="30px"
				paddingTop="30px"
			>
				<Typography fontSize="20px" fontWeight="bold" color="darkred">
					Delete Account
				</Typography>
				<Stack
					direction="column"
					alignItems="start"
					justifyContent="center"
					spacing="10px"
				>
					<Typography>
						Once you delete your account, there is no going back. Please be
						certain.
					</Typography>
					<Button
						variant="contained"
						style={{
							paddingLeft: "20px",
							paddingRight: "20px",
							backgroundColor: "red",
							textTransform: "none",
						}}
					>
						Delete account
					</Button>
				</Stack>
			</Stack>
		</Stack>
	);
};
