import { Button, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { MultiSelect, Option } from 'react-multi-select-component';
import { AvailableVideosTable } from './AvailableVideosTable';
import {
	LocalizationProvider,
	TimePicker,
} from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import apiClient from '../../ApiClient';
import axios from 'axios';
import { min } from 'lodash';
import { ErrorDialog } from '../../Helpers/ErrorDialog';
import { RecurringSchedule } from '../Dashboard/DashboardPage';

export interface Video {
	id: number;
	title: string;
	video: File;
	description: string;
	group: string;
	posted: boolean;
	user: number;
}

export interface TiktokAccount {
	id: number;
	username: string;
	access_token: string;
	refresh_token: string;
	user: number
}

export const SchedulePage = () => {

	const [videos, setVideos] = useState<Video[]>([]);
	const [accounts, setAccounts] = useState<Option[]>([]);
	const [schedules, setSchedules] = useState<RecurringSchedule[]>([]);
	const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
	const [selectedAccounts, setSelectedAccounts] = useState<Option[]>([]);
	const [uploadDateTime, setUploadDateTime] = useState<(Dayjs|null)[]>([null, null, null, null, null]);
	const [error, setError] = useState<string | null>(null);
	const [errorTitle, setErrorTitle] = useState<string | null>(null);
	const [uploading, setUploading] = useState<boolean>(false);
	
	useEffect(() => {
		getRelevantData();
	}, []);

	const getRelevantData = async () => {
		const videos = await getVideos();
		const accounts = await getTiktokAccounts();
		const schedules = await getRecurringSchedules();
		setVideos(videos);
		setSchedules(schedules);
		setAccounts(accounts.map(a => ({label: a.username, value: a.id})));
		setSelectedAccounts([]);
		setSelectedGroup(null);
	}

	const getRecurringSchedules = async () => {
		return await apiClient.get<RecurringSchedule[]>("core/recurringschedule/")
		.then(response => {
			const scheduleResponse = response.data;
			return scheduleResponse;
		}).catch((err) => {
			setErrorTitle("Failed to fetch schedules")
			if (axios.isAxiosError(err)) {
				const errorMessage = err.response?.data?.message || 'An error occurred while fetching schedules.';
				setError(errorMessage);
			} else {
				setError('An unexpected error occurred while fetching schedules.');
			}
			return [] as RecurringSchedule[];
		});
	}

	const getVideos = async () => {
		return await apiClient.get<Video[]>("core/videos/")
		.then(response => {
			const videosResponse = response.data;
			return videosResponse;
		}).catch((err) => {
			setErrorTitle("Failed to fetch videos")
			if (axios.isAxiosError(err)) {
				const errorMessage = err.response?.data?.message || 'An error occurred while fetching videos.';
				setError(errorMessage);
			} else {
				setError('An unexpected error occurred while fetching videos.');
			}
			return [] as Video[];
		});
	};

	const getTiktokAccounts = async () => {
		return await apiClient.get<TiktokAccount[]>("core/tiktokaccounts/")
		.then(response => {
			const accountsResponse = response.data;
			return accountsResponse;
		}).catch((err) => {
			setErrorTitle("Failed to fetch tiktok accounts")
			if (axios.isAxiosError(err)) {
				const errorMessage = err.response?.data?.message || 'An error occurred while fetching tiktok accounts.';
				setError(errorMessage);
			} else {
				setError('An unexpected error occurred while fetching tiktok accounts.');
			}
			return [] as TiktokAccount[];
		});
	}

	const handleToggleGroupSelection = (group: string) => {
		if(selectedGroup === group){
			setSelectedGroup(null);
		}else{
			setSelectedGroup(group);
		}
		setUploadDateTime([null, null, null, null, null]);
	};

	const completeSchedule = async () => {
		if(selectedGroup === null || selectedAccounts.length === 0 || uploadDateTime.every(dt => dt === null)){
			setErrorTitle("Failed to schedule posts");
			setError("please complete all required fields");
			return;
		}
		setUploading(true);
		for(const account of selectedAccounts){
			const formData = new FormData();
			formData.append('group', selectedGroup);
			let i = 1;
			for(const dt of uploadDateTime.filter(dt => dt !== null)){
				formData.append(`post_time_${i}`, dt!.toISOString().split('T')[1]);
				i++;
			}
			formData.append('tiktok_account', account.value);
			await apiClient.post("core/recurringschedule/", formData)
			.catch((error) => {
				setErrorTitle("Failed to schedule posts")
				if (axios.isAxiosError(error)) {
					const errorMessage = error.response?.data?.message || 'An error occurred while scheduling posts.';
					setError(errorMessage);
				} else {
					setError('An unexpected error occurred while scheduling posts.');
				}
			});
		};
		setSelectedAccounts([]);
		setSelectedGroup(null);
		setUploadDateTime([null, null, null, null, null]);
		setUploading(false);
	}

	if(uploading) return (
		<Stack
			direction="column"
			flex={1}
			divider={
				<Divider
					orientation="horizontal"
					flexItem
				/>
			}
			justifyContent="center"
			alignItems="center"
		>
			<CircularProgress/>
		</Stack>
	);

	return (
		<Stack
			direction="column"
			flex={1}
			divider={
				<Divider
					orientation="horizontal"
					flexItem
				/>
			}
		>
			<Stack
				direction="row"
				justifyContent="space-between"
				paddingX="20px"
				paddingY="20px"
			>
				<Typography
					fontSize="25px"
					fontWeight="bold"
				>
					Schedule Posts
				</Typography>
				<Button
					variant="contained"
					disabled={selectedGroup === null || selectedAccounts.length === 0 || uploadDateTime.every(dt => dt === null) || uploading}
					onClick={completeSchedule}
					style={{
						textTransform:
							'none',
					}}
				>
					Complete
				</Button>
			</Stack>
			<Stack
				direction="column"
				height="100%"
				alignItems="start"
				justifyContent="start"
				spacing="20px"
				paddingX="30px"
				paddingTop="30px"
			>
				<Stack
					direction="column"
					justifyContent="center"
					alignItems="start"
					width="100%"
					spacing="5px"
					overflow="auto"
				>
					<Typography color="grey">
						Videos Selected
					</Typography>
					<AvailableVideosTable
						videos={videos}
						selectedGroup={
							selectedGroup
						}
						handleToggleGroupSelection={
							handleToggleGroupSelection
						}
					/>
				</Stack>
				<Stack
					direction="column"
					spacing="5px"
					width="50%"
				>
					<Typography color="grey">
						Tiktok Accounts
					</Typography>

					<MultiSelect
						options={
							accounts.map(a => 
								schedules.some(s => 
									s.group === selectedGroup && 
									s.tiktok_account === a.value
								) ?
								{...a, disabled: true}:
								a 
							)
						}
						value={
							selectedAccounts
						}
						onChange={
							setSelectedAccounts
						}
						overrideStrings={{
							allItemsAreSelected:
								'All',
						}}
						labelledBy="Select Accounts"
					/>
				</Stack>
				<Stack
					direction="column"
					justifyContent="center"
					alignItems="start"
					width="100%"
					spacing="5px"
				>
					<Typography color="grey">
						Schedule Daily post times (up to 5 times)
					</Typography>
					{Array.from({length: min([5, videos.filter(v => v.group === selectedGroup).length]) ?? 0}).map((v, i) => (
						<Stack
							direction="row"
							justifyContent="start"
							alignItems="center"
							spacing="10px"
						>
							<LocalizationProvider
								dateAdapter={
									AdapterDayjs
								}
							>
								<TimePicker
									value={
										uploadDateTime[i]
									}
									onChange={
										(dt, _) => setUploadDateTime(uploadDateTime.map((d, j) => j === i ? dt : d))
									}
								/>
							</LocalizationProvider>
						</Stack>
					))}
				</Stack>
				<ErrorDialog
					title={errorTitle}
					error={error}
					onClose={() => {
						setError(null);
						setErrorTitle(null);
					}}
				/>
			</Stack>
		</Stack>
	);
};
