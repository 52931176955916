import './index.css';
import LoginPage from './UserAuthentication/LoginPage';
import UploadPage from './pages/Upload/UploadPage';
import { theme } from './services/Theme';
import { AppRoutes } from './ApplicationRoutes';
import ForgotPasswordPage from './UserAuthentication/ForgotPasswordPage';
import { ThemeProvider } from '@emotion/react';
import SignupPage from './UserAuthentication/SignupPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { GeneralLayout } from './Layouts/GeneralLayout';
import { DashboardPage } from './pages/Dashboard/DashboardPage';
import { AnalyticsPage } from './pages/AnalyticsPage';
import { SupportPage } from './pages/SupportPage';
import { SettingsPage } from './pages/Settings/SettingsPage';
import { SchedulePage } from './pages/Schedule/SchedulePage';
import { AuthProvider } from './Contexts/AuthContext';
import { RequireAuth } from './ProtectedRoute';
import { NotFound } from './pages/NotFound';
import { GalleryPage } from './pages/Gallery/GalleryPage';
import { TermsOfServicePage } from './pages/TermsOfServicePage'
import { PrivacyPolicyPage } from './pages/PrivacyPolicyPage'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path={AppRoutes.Login} Component={LoginPage} />
            <Route path={AppRoutes.Signup} Component={SignupPage} />
            <Route
              path={AppRoutes.ForgotPassword}
              Component={ForgotPasswordPage}
            />
            <Route
              path={AppRoutes.TermsOfService}
              Component={TermsOfServicePage}
            />
            <Route
              path={AppRoutes.PrivacyPolicy}
              Component={PrivacyPolicyPage}
            />
            {/* <Route path={AppRoutes.VerifyEmail} Component={VerifyEmailPage} /> */}
            {/* <Route path={AppRoutes.ResetPassword} Component={ResetPasswordPage} /> */}
            <Route
              path={AppRoutes.Dashboard}
              element={
                <RequireAuth>
                  <GeneralLayout>
                    <DashboardPage />
                  </GeneralLayout>
                </RequireAuth>
              }
            />
            <Route
              path={AppRoutes.Gallery}
              element={
                <RequireAuth>
                  <GeneralLayout>
                    <GalleryPage />
                  </GeneralLayout>
                </RequireAuth>
              }
            />
            <Route
              path={AppRoutes.Upload}
              element={
                <RequireAuth>
                  <GeneralLayout>
                    <UploadPage />
                  </GeneralLayout>
                </RequireAuth>
              }
            />
            <Route
              path={AppRoutes.Schedule}
              element={
                <RequireAuth>
                  <GeneralLayout>
                    <SchedulePage />
                  </GeneralLayout>
                </RequireAuth>
              }
            />
            <Route
              path={AppRoutes.Analytics}
              element={
                <RequireAuth>
                  <GeneralLayout>
                    <AnalyticsPage />
                  </GeneralLayout>
                </RequireAuth>
              }
            />
            <Route
              path={AppRoutes.Support}
              element={
                <RequireAuth>
                  <GeneralLayout>
                    <SupportPage />
                  </GeneralLayout>
                </RequireAuth>
              }
            />
            <Route
              path={AppRoutes.Settings}
              element={
                <RequireAuth>
                  <GeneralLayout>
                    <SettingsPage />
                  </GeneralLayout>
                </RequireAuth>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
