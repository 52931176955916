import { ReactNode } from 'react';
import { useAuth } from './Contexts/AuthContext';
import { Navigate, useLocation } from 'react-router-dom';

export const RequireAuth: React.FC<{ children: ReactNode }> = ({
	children
}) => {
	const { isAuthenticated } = useAuth();
	const location = useLocation();

	return isAuthenticated ? (
		<>{children}</>
	) : (
		<Navigate
			to="/login"
			replace
			state={{
				path: location.pathname,
			}}
		/>
	);
};
