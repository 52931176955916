export const AppRoutes = {
    Dashboard: '/',
    Upload: '/upload',
    Gallery: '/gallery',
    Analytics: '/analytics',
    Settings: '/settings',
    Support: '/support',
    Schedule: '/schedule',
    Login: '/login',
    Signup: '/signup',
    ForgotPassword: '/forgotpassword',
    VerifyEmail: '/verifyemail',
    ResetPassword: '/resetpassword',
    TermsOfService: '/termsofservice',
    PrivacyPolicy: '/privacypolicy',
}