import type { TiktokAccounts } from "./SettingsPage";
import { HighlightOff } from "@mui/icons-material";
import { Button, DialogContent, DialogTitle } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { useState } from "react";

interface TiktokAccountItemProps {
	tiktokAccount: TiktokAccounts;
}

export default function TiktokAccountItem({
	tiktokAccount,
}: TiktokAccountItemProps) {
	const [open, setOpen] = useState<boolean>(false);
	return (
		<div className="flex border-2 rounded-md gap-6 px-2 py-2 bg-gray-100">
			<h1 className="text-lg font-semibold w-full">
				@{tiktokAccount.username}
			</h1>
			<button
				type="button"
				className="bg-gray-600 text-white p-1 rounded-md flex gap-1 font-medium items-center"
				onClick={() => setOpen(true)}
			>
				<HighlightOff fontSize="small" />
			</button>
			<Dialog open={open} onClose={() => setOpen(false)}>
				<div className="p-2">
					<DialogTitle>
						<div className="flex flex-col items-center gap-2">
							<div className="font-bold text-black text-xl">
								Remove @{tiktokAccount.username}
							</div>
							<span className="text-black text-lg">
								You will no longer be able to upload videos to{" "}
								<span className="font-semibold">@{tiktokAccount.username}</span>
							</span>
						</div>
					</DialogTitle>
					<DialogContent>
						<div className="flex justify-center gap-2">
							<Button
								variant="contained"
								style={{ backgroundColor: "#313131" }}
								onClick={() => setOpen(false)}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								style={{ backgroundColor: "#CB4242" }}
							>
								Remove
							</Button>
						</div>
					</DialogContent>
				</div>
			</Dialog>
		</div>
	);
}
