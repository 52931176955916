import {
  TableRow,
  TableCell,
  Typography,
  Tooltip,
} from '@mui/material';
import { FC } from 'react';
import { TiktokAccount, Video } from '../Schedule/SchedulePage';
import { RecurringSchedule } from './DashboardPage';

interface Props {
  group: string;
  videos: Video[];
  schedule: RecurringSchedule;
  account: TiktokAccount | undefined;
}

export const GroupScheduleRow: FC<Props> = ({
  group,
  videos,
  schedule,
  account
}) => {
  const scheduleTimes = [schedule.post_time_1, schedule.post_time_2, schedule.post_time_3, schedule.post_time_4, schedule.post_time_5].filter(t => t);
  return (
    <TableRow>
      <TableCell>{group}</TableCell>
      <TableCell align="center">{videos.length}</TableCell>
      <TableCell align="center">{videos.filter(v => v.posted).length}</TableCell>
      <TableCell align="center">
        <Tooltip title={
          <>
            {scheduleTimes.map(t => (
              <><span>{t}</span><br/></>
            ))}
          </>
        }>
          <Typography>Daily</Typography>
        </Tooltip></TableCell>
      <TableCell align="center">
        {account?.username ?? "N/A"}
      </TableCell>
      {/* <TableCell align="center">
        <IconButton children={<Edit />} />
        <IconButton children={<Delete />} />
      </TableCell> */}
    </TableRow>
  );
};
