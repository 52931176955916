import {
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Checkbox,
	Typography,
	IconButton,
} from '@mui/material';
import { FC, useState } from 'react';
import { Video } from './SchedulePage';
import { groupBy } from 'lodash';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

interface Props {
	videos: Video[];
	selectedGroup: string | null;
	handleToggleGroupSelection: (g: string) => void;
}

export const AvailableVideosTable: FC<Props> = ({
	videos,
	selectedGroup,
	handleToggleGroupSelection,
}) => {
	const [expandGroup, setExpandGroup] = useState<string[]>([]);
	const toggleExpandGroup = (g: string) => {
		if(expandGroup.includes(g)){
			setExpandGroup(expandGroup.filter(group => group !== g))
		}else{
			setExpandGroup([...expandGroup, g])
		}
	}
	return (
		<TableContainer>
			<Table
				style={{
					border: '1px solid rgb(0,0,0,0.1)',
				}}
			>
				<TableHead>
					<TableRow>
						<TableCell padding="checkbox"></TableCell>
						<TableCell padding="checkbox"></TableCell>
						<TableCell align="center">
							Group
						</TableCell>
						<TableCell align="center">
							Title
						</TableCell>
						<TableCell align="center">
							Description
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{videos && videos.length > 0 ? 
						Object.entries(groupBy(videos, v => v.group)).map(([g,videos]) => (
							<>
								<TableRow>
									<TableCell align="left">
										<Checkbox
											size="small"
											checked={selectedGroup === g}
											onClick={() =>
												handleToggleGroupSelection(g)
											}
										/>
									</TableCell>
									<TableCell align="left">
										<IconButton
											size="small"
											onClick={() => toggleExpandGroup(g)}
											children={
												expandGroup.includes(g) ?
													<ExpandLess/>:
													<ExpandMore/>
											}
										/>
									</TableCell>
									<TableCell align='center'>
										{g}
									</TableCell>
									<TableCell colSpan={2}></TableCell>
								</TableRow>
								{expandGroup.includes(g) && videos.map(v => (
									<TableRow>
										<TableCell colSpan={3}></TableCell>
										<TableCell align="center">
											<Typography>
												{v.title}
											</Typography>
										</TableCell>
										<TableCell align="center">
											<Typography>
												{v.description}
											</Typography>
										</TableCell>
									</TableRow>
								))}
							</>
						)):
						<TableRow>
							<TableCell colSpan={5} align='center'>
								<Typography color="gray">No videos available</Typography>
							</TableCell>
						</TableRow>
					}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
